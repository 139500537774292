export class Goals {
  public home: number;
  public guest: number;

  public constructor(home: number, guest: number) {
    this.home = home;
    this.guest = guest;
  }
}

export default Goals;
